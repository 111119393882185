import React,{useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { open_contact } from "../redux/features/chat/chatSlice";
import {
  getLoginStatus,getUser,selectIsLoggedIn,selectUser,
} from "../redux/features/auth/authSlice";
import SocketContext from "../context/SocketContext";
import axios from "axios";

export const shortenText = (text, n) => {
  if (text?.length > n) {
    const shoretenedText = text.substring(0, n).concat("...");
    return shoretenedText;
  }
  return text;
};

const Contact = ({ contact, setSearchResults, socket, isDisabled }) => {

	const dispatch = useDispatch();
	// const { user } = useSelector((state) => state.auth);
 //  	const {token} = user;
  	const values = {
	    receiver_id: contact._id,
	    // user:user.token,
	};
	const isLoggedIn = useSelector(selectIsLoggedIn);
	const user = useSelector(selectUser);
	// useEffect(() => {
	//     dispatch(getLoginStatus());
	//     if (isLoggedIn && user === null) {
	//       dispatch(getUser());
	//     }

	// }, [dispatch, isLoggedIn, user]);
	// alert(contact.email);
	const openConversation = async (name) => {
		let newChat = await dispatch(open_contact(values));
	  socket.emit("join conversation", newChat.payload._id);
	  // console.log(newChat.payload._id);
	    // dispatch(open_create_conversation(values));
	    setSearchResults([]);
	    // const conversationType = contact.name;
	    let conversationType;
	    if (contact.email === 'online.training1@kbmgroup.co.uk' || user.email === 'online.training1@kbmgroup.co.uk') {
			    conversationType = 'Online Training 1';
			} else if (contact.email === 'online.training2@kbmgroup.co.uk' || user.email === 'online.training2@kbmgroup.co.uk') {
			    conversationType = 'Online Training 2';
			} else if (contact.email === 'barking@kbmgroup.co.uk' || user.email === 'barking@kbmgroup.co.uk') {
			    conversationType = 'Barking';
			} else if (contact.email === 'parkroyal@kbmgroup.co.uk' || user.email === 'parkroyal@kbmgroup.co.uk') {
			    conversationType = 'Park Royal';
			} else if (contact.email === 'manchester@kbmgroup.co.uk' || user.email === 'manchester@kbmgroup.co.uk') {
			    conversationType = 'Manchester';
			} else if (contact.email === 'birmingham@kbmgroup.co.uk' || user.email === 'birmingham@kbmgroup.co.uk') {
			    conversationType = 'Birmingham';
			} else if (contact.email === 'croydon@kbmgroup.co.uk' || user.email === 'croydon@kbmgroup.co.uk') {
			    conversationType = 'Croydon';
			} else if (contact.email === 'onlinebookkeeping_g1@kbmgroup.co.uk' || user.email === 'onlinebookkeeping_g1@kbmgroup.co.uk') {
			    conversationType = 'Online BP G1';
			} else if (contact.email === 'onlinebookkeeping_g2@kbmgroup.co.uk' || user.email === 'onlinebookkeeping_g2@kbmgroup.co.uk') {
			    conversationType = 'Online BP G2';
			} else if (contact.email === 'online_accounts_assistant_g1@kbmgroup.co.uk' || user.email === 'online_accounts_assistant_g1@kbmgroup.co.uk') {
			    conversationType = 'Online AA G1';
			} else if (contact.email === 'online_accounts_assistant_g2@kbmgroup.co.uk' || user.email === 'online_accounts_assistant_g2@kbmgroup.co.uk') {
			    conversationType = 'Online AA G2';
			} else if (contact.email === 'fa_practice@kbmgroup.co.uk' || user.email === 'fa_practice@kbmgroup.co.uk') {
			    conversationType = 'FA Practice';
			} else if (contact.email === 'online_accounts_assistant@kbmgroup.co.uk' || user.email === 'online_accounts_assistant@kbmgroup.co.uk') {
			    conversationType = 'Online AA';
			} else if (contact.email === 'online_bookkeeping_payroll@kbmgroup.co.uk' || user.email === 'online_bookkeeping_payroll@kbmgroup.co.uk') {
			    conversationType = 'Online BP';
			} else {
			    // Default conversation type if the email doesn't match any pattern
			    conversationType = 'support';
			}

	    // console.log(conversationType)

	  //   try {
    //     const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/conversation/${newChat.payload._id}`, {
    //         method: 'PATCH',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({ conversationType: 'conversationType' }),
    //     });
    //     console.log(response);
    //     if (!response.ok) {
    //         throw new Error('Failed to save type');
    //     }
        
    //     console.log('Type saved successfully');
    // } catch (error) {
    //     console.error('Error saving type:', error.message);
    // }
	    try {
	        const config = {
	          headers: {
	            "Content-type": "application/json",
	          },
	        };
	        const { data } = await axios.patch(
	          `${process.env.REACT_APP_BACKEND_URL}/api/conversation/${newChat.payload._id}`,
	          {
	            conversationType: conversationType,
	          },
	          config
	        );
	        // console.log(data);
	      } catch (error) {
	      	console.error("Error saving type");
	      }
	};

	return (
		<>
			<li className={isDisabled ? 'disabledUser' : ''} onClick={() => openConversation(contact.name)}>
				<div className="chatImg">
					<img src={contact.picture} alt={contact.name}/>
				</div>
				<div className="chatDetail">
					<h6>{shortenText(contact.name)}</h6>
					<p>{contact.email}</p>
				</div>
			</li>
		</>
	)
}

const ContactWithContext = (props) => (
  <SocketContext.Consumer>
    {(socket) => <Contact {...props} socket={socket} />}
  </SocketContext.Consumer>
);

export default ContactWithContext