import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import{NotificationIcon,ArrowIcon,CloseIcon} from '../svg';

const Notifications = () => {
	const [show, setShow] = useState(false);
	const [feedBackModal, setFeedBackModal] = useState(false);
	// const handleFeedBackModalShow = () => setFeedBackModal(true);
	const handleFeedBackModalClose = () => setFeedBackModal(false);
	const [angryFeedBackModal, setAngryFeedBackModal] = useState(false);
	const handleAngryFeedBackModalShow = () => setAngryFeedBackModal(true);
	const handleAngryFeedBackModalClose = () => setAngryFeedBackModal(false);
	const { user, users } = useSelector((state) => state.auth);
	const [formData, setFormData] = useState({
		complaintType: '',
		description: '',
		trainer: '',
		//client: ''
	});
	const handleFeedBackModalShow = (moodType) => {
	    // Set feedbackType in the state based on clicked mood
	    setFeedbackData({ ...feedbackData, feedbackType: moodType });
	    // Open the modal
	    setFeedBackModal(true);
	};
	const [toastShow, setToastShow] = useState(false);
	const [showComplaintDiv1, setShowComplaintDiv1] = useState(false);
	const [showComplaintDiv2, setShowComplaintDiv2] = useState(false);
	const dispatch = useDispatch();
	const [trainers, setTrainers] = useState([]);
	//const [clients, setClients] = useState([]);

	const [feedbackData, setFeedbackData] = useState({
        expectations: '',
        objectives: '',
        knowledgeable: '',
        encouraged: '',
        discussion: ''
    });

    const [angryFeedbackData, setAngryFeedbackData] = useState({
        sessionLocation: '',
        course: '',
        client: '',
        topic: '',
        software: '',
        description: ''
    });



		useEffect(() => {
        const fetchTrainers = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/getTrainers`, {
                    params: {
                        // formData or other query parameters
                    }
                });
                setTrainers(response.data);
                //console.log('Trainers data:', response.data);
            } catch (error) {
                // setError('Error fetching trainers data');
                console.error('Error fetching trainers data:', error);
            }
        };

        if (showComplaintDiv1) {
	        fetchTrainers();
	    }
    }, [showComplaintDiv1]);
		useEffect(() => {
        /*const fetchClients = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/getStudentAllocations`, {
                    params: {
                        // formData or other query parameters
                    }
                });
				setClients(response.data);
                //console.log('Clients data:', response.data);
            } catch (error) {
                // setError('Error fetching trainers data');
                console.error('Error fetching trainers data:', error);
            }
        };*/

		//fetchClients();
    }, []);

	// useEffect(() => {
	// 	setGetTrainer(users)
	// 	dispatch(getTrainers());
	// }, [dispatch]);

	// console.log(getTrainer)

	const handleComplaintBox = () => {
		// dispatch(getTrainers(users));
	};

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handleChange = (e) => {
	    const { name, value } = e.target;
	    setFormData((prevState) => {
	      let newFormData = { ...prevState, [name]: value };
	      if (name === 'complaintType') {
	        if (value === 'Trainer') {
	          newFormData = { ...newFormData, client: '' };
	          setShowComplaintDiv1(true);
	          setShowComplaintDiv2(false);
	        } else if (value === 'Training') {
	          newFormData = { ...newFormData, trainer: '' };
	          setShowComplaintDiv1(false);
	          setShowComplaintDiv2(true);
	        } else {
	          setShowComplaintDiv1(false);
	          setShowComplaintDiv2(false);
	        }
	      }
	      return newFormData;
	    });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!formData.complaintType){
			return toast.error('Complaint type is required')
		}
		try {
			await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/createComplaint`, formData);
			setFormData({ complaintType: "", description: "", trainer: "", client: "" });
			setShow(false);
			setToastShow(true);
			setShowComplaintDiv1(false);
			setShowComplaintDiv2(false);
			toast.success('Complaint sent !');
		} catch (error) {
			console.error('Error saving data', error);
		}
	};

	const handleFeedbackChange = (e) => {
        const { name, value } = e.target;
        setFeedbackData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

	const handleFeedbackSubmit = async (e) => {
		e.preventDefault();
		if (!feedbackData.expectations || !feedbackData.objectives || !feedbackData.knowledgeable || !feedbackData.encouraged || !feedbackData.discussion || !feedbackData.feedbackType || !feedbackData.rate) {
            return toast.error('Please complete all feedback sections');
        }
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/createFeedBack`, feedbackData);
            console.log(feedbackData)
            toast.success('Feedback submitted successfully!');
            // Reset feedback data after successful submission
        setFeedbackData({
            expectations: "",
            objectives: "",
            knowledgeable: "",
            encouraged: "",
            discussion: "",
            rate: "",
            feedbackType: "", // Reset the feedbackType as well
        });
            handleFeedBackModalClose();
        } catch (error) {
            console.error('Error submitting feedback:', error);
            toast.error('Failed to submit feedback. Please try again later.');
        }
	};

	const handleInputChange = (e) => {
	    const { name, value } = e.target;
	    setAngryFeedbackData(prevState => ({
	        ...prevState,
	        [name]: value
	    }));
	};

	const handleAngryFeedbackSubmit = async (e) => {
	    e.preventDefault();
	    const { sessionLocation, course, client, topic, software, description } = angryFeedbackData;

	    if (!sessionLocation || !course || !topic || !software || !description) {
	        return toast.error('Please complete all feedback sections');
	    }

	    // Include feedbackType in the data being submitted
	    const feedbackDataWithType = { ...angryFeedbackData, feedbackType: "angry" };

	    try {
	        // Send feedbackDataWithType instead of angryFeedbackData
	        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/createFeedBack`, feedbackDataWithType);

	        // Reset form data
	        setAngryFeedbackData({ sessionLocation: "", course: "", client: "", topic: "", software: "", description: "" });

	        console.log(feedbackDataWithType);
	        toast.success('Feedback submitted successfully!');
	        handleAngryFeedBackModalClose();
	    } catch (error) {
	        console.error('Error submitting feedback:', error);
	        toast.error('Failed to submit feedback. Please try again later.');
	    }
	};

	const [courses, setCourses] = useState([]); // Ensure initial state is an array
	const [selectedCourse, setSelectedCourse] = useState('');
	const [clients, setClients] = useState([]);

	const handleCourseChange = (e) => {
		const selectedCourseName = e.target.value;
		setSelectedCourse(selectedCourseName);

		const selectedCourseData = courses.find(course => course.courseName === selectedCourseName);
		setClients(selectedCourseData ? selectedCourseData.coursesClients : []);

		// Update angryFeedbackData with the selected course
	    setAngryFeedbackData(prevState => ({
	        ...prevState,
	        course: selectedCourseName
	    }));
	};

	useEffect(() => {
		const fetchCourses = async () => {
			try {
				const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/getStudentAllocations`); // Replace with your actual endpoint
				console.log('API Response:', response.data);
				const coursesData = response.data;

				if (Array.isArray(coursesData)) {
					setCourses(coursesData);
					console.log('Courses state updated:', coursesData); // Log the updated state
				} else {
					console.error('Expected an array of courses, but received:', coursesData);
				}
			} catch (error) {
				console.error('Error fetching courses:', error);
			}
		};

		if (angryFeedBackModal) {
	        fetchCourses();
	    }
	}, [angryFeedBackModal]);

	return (
		<>

			<div className="notificationSec d-flex align-items-center justify-content-between">
				{user?.roles?.some(role => role !== 'student') && (
				<div className="d-flex">
					<div className="bellIcon">
						<NotificationIcon/>
					</div>
					<div className="d-flex flex-column px-2 notificationContent">
						<span>Get notified of new messages</span>
						<span>
			              Turn on desktop notifications
			              <ArrowIcon />
			            </span>
					</div>
				</div>
				)}
				{user?.roles?.some(role => role === 'student') && (
					<>
					<div className="feedBack">
						<h5>Feedback</h5>
						<ul>
							<li onClick={() => { handleFeedBackModalShow("happy");}} className="happyMood">
								<img src={`../../fileImages/emoji3.png`} />
							</li>
							<li onClick={() => { handleFeedBackModalShow("neutral");}} className="neutralMood">
								<img src={`../../fileImages/emoji2.png`} />
							</li>
							<li onClick={() => { handleAngryFeedBackModalShow();}}>
								<img src={`../../fileImages/emoji1.png`} />
							</li>
						</ul>
					</div>
					{/*<button type="button" className="btn btn-secondary" onClick={() => { handleShow(); handleComplaintBox(); }}>
						Complaint Box
					</button>*/}
					</>
				)}
				{user?.roles?.some(role => role !== 'student') && (
				<div className="notificationCLose">
					<CloseIcon />
				</div>
				)}
			</div>

			

			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Log a complaint</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form>
						<div className="form-group">
							<label htmlFor="complaintType">Complaint About</label>
							<select
								className="form-control"
								id="complaintType"
								name="complaintType"
								value={formData.complaintType}
								onChange={handleChange}
							>
								<option value="">Select Complaint Type</option>
								<option value="Trainer">Trainer</option>
								<option value="Training">Training</option>
							</select>
						</div>

						{showComplaintDiv1 && (
							<div className="complaintDiv1">
								<div className="form-group">
									<label htmlFor="trainer">Select Trainer</label>
									<select
										className="form-control"
										id="trainer"
										name="trainer"
										value={formData.trainer}
										onChange={handleChange}
									>
										<option value="">Select Trainer</option>
										{trainers && trainers.map((user) => (
											<option key={user._id} value={user._id}>
												{user.name}
											</option>
										))}
									</select>
								</div>
								<div className="form-group">
									<label htmlFor="description">Description</label>
									<textarea
										className="form-control"
										id="description"
										name="description"
										rows="3"
										value={formData.description}
										onChange={handleChange}
									></textarea>
								</div>
							</div>
						)}

						{showComplaintDiv2 && (
							<div className="complaintDiv2">
								<div className="form-group">
									<label htmlFor="client">Select Client</label>
									<select
										className="form-control"
										id="client"
										name="client"
										value={formData.client}
										onChange={handleChange}
									>
										<option>Select a client</option>
										{clients.map((course, index) => (
											<option key={index} value={course}>
												{course}
											</option>
										))}
									</select>
								</div>
								<div className="form-group">
									<label htmlFor="description">Description</label>
									<textarea
										className="form-control"
										id="description"
										name="description"
										rows="3"
										value={formData.description}
										onChange={handleChange}
									></textarea>
								</div>
							</div>
						)}
					</form>
				</Modal.Body>
				<Modal.Footer>
					<div className="d-flex">
						<button type="button" className="btn btn-secondary" onClick={handleSubmit}>
							Submit
						</button>
						<button type="button" className="btn btn-secondary mx-3" onClick={handleClose}>
							Close
						</button>
					</div>
				</Modal.Footer>
			</Modal>

			<Modal show={feedBackModal} onHide={handleFeedBackModalClose}>
				<Modal.Header closeButton>
					<Modal.Title>Feedback</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form className="feedBackEmotionForm">
						<div className="feedBackEmotions">
							<p>Did the training meet your expectations, and can you apply the knowledge learned?</p>
							<ul>
								<li>
									<input type="radio" id="stronglyAgree" name="expectations" value="Strongly Agree" checked={feedbackData.expectations === 'Strongly Agree'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="stronglyAgree">Strongly Agree</label>
								</li>
								<li>
									<input type="radio" id="agree" name="expectations" value="Agree" checked={feedbackData.expectations === 'Agree'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="agree">Agree</label>
								</li>
								<li>
									<input type="radio" id="neutral" name="expectations" value="Neutral" checked={feedbackData.expectations === 'Neutral'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="neutral">Neutral</label>
								</li>
								<li>
									<input type="radio" id="disagree" name="expectations" value="Disagree" checked={feedbackData.expectations === 'Disagree'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="disagree">Disagree</label>
								</li>
								<li>
									<input type="radio" id="stronglyDisagree" name="expectations" value="Strongly Disagree" checked={feedbackData.expectations === 'Strongly Disagree'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="stronglyDisagree">Strongly Disagree</label>
								</li>
							</ul>
						</div>
						<div className="feedBackEmotions">
							<p>Were the objectives clear and the content organized?</p>
							<ul>
								<li>
									<input type="radio" id="stronglyAgree1" name="objectives" value="Strongly Agree" checked={feedbackData.objectives === 'Strongly Agree'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="stronglyAgree1">Strongly Agree</label>
								</li>
								<li>
									<input type="radio" id="agree1" name="objectives" value="Agree" checked={feedbackData.objectives === 'Agree'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="agree1">Agree</label>
								</li>
								<li>
									<input type="radio" id="neutral1" name="objectives" value="Neutral" checked={feedbackData.objectives === 'Neutral'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="neutral1">Neutral</label>
								</li>
								<li>
									<input type="radio" id="disagree1" name="objectives" value="Disagree" checked={feedbackData.objectives === 'Disagree'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="disagree1">Disagree</label>
								</li>
								<li>
									<input type="radio" id="stronglyDisagree1" name="objectives" value="Strongly Disagree" checked={feedbackData.objectives === 'Strongly Disagree'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="stronglyDisagree1">Strongly Disagree</label>
								</li>
							</ul>
						</div>
						<div className="feedBackEmotions">
							<p>Were the materials useful, and was the trainer knowledgeable?</p>
							<ul>
								<li>
									<input type="radio" id="stronglyAgree2" name="knowledgeable" value="Strongly Agree" checked={feedbackData.knowledgeable === 'Strongly Agree'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="stronglyAgree2">Strongly Agree</label>
								</li>
								<li>
									<input type="radio" id="agree2" name="knowledgeable" value="Agree" checked={feedbackData.knowledgeable === 'Agree'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="agree2">Agree</label>
								</li>
								<li>
									<input type="radio" id="neutral2" name="knowledgeable" value="Neutral" checked={feedbackData.knowledgeable === 'Neutral'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="neutral2">Neutral</label>
								</li>
								<li>
									<input type="radio" id="disagree2" name="knowledgeable" value="Disagree" checked={feedbackData.knowledgeable === 'Disagree'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="disagree2">Disagree</label>
								</li>
								<li>
									<input type="radio" id="stronglyDisagree2" name="knowledgeable" value="Strongly Disagree" checked={feedbackData.knowledgeable === 'Strongly Disagree'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="stronglyDisagree2">Strongly Disagree</label>
								</li>
							</ul>
						</div>
						<div className="feedBackEmotions">
							<p>Was the instruction quality good, and was participation encouraged?</p>
							<ul>
								<li>
									<input type="radio" id="stronglyAgree3" name="encouraged" value="Strongly Agree" checked={feedbackData.encouraged === 'Strongly Agree'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="stronglyAgree3">Strongly Agree</label>
								</li>
								<li>
									<input type="radio" id="agree3" name="encouraged" value="Agree" checked={feedbackData.encouraged === 'Agree'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="agree3">Agree</label>
								</li>
								<li>
									<input type="radio" id="neutral3" name="encouraged" value="Neutral" checked={feedbackData.encouraged === 'Neutral'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="neutral3">Neutral</label>
								</li>
								<li>
									<input type="radio" id="disagree3" name="encouraged" value="Disagree" checked={feedbackData.encouraged === 'Disagree'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="disagree3">Disagree</label>
								</li>
								<li>
									<input type="radio" id="stronglyDisagree3" name="encouraged" value="Strongly Disagree" checked={feedbackData.encouraged === 'Strongly Disagree'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="stronglyDisagree3">Strongly Disagree</label>
								</li>
							</ul>
						</div>
						<div className="feedBackEmotions">
							<p>Was there enough time for questions and discussion?</p>
							<ul>
								<li>
									<input type="radio" id="stronglyAgree4" name="discussion" value="Strongly Agree" checked={feedbackData.discussion === 'Strongly Agree'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="stronglyAgree4">Strongly Agree</label>
								</li>
								<li>
									<input type="radio" id="agree4" name="discussion" value="Agree" checked={feedbackData.discussion === 'Agree'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="agree4">Agree</label>
								</li>
								<li>
									<input type="radio" id="neutral4" name="discussion" value="Neutral" checked={feedbackData.discussion === 'Neutral'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="neutral4">Neutral</label>
								</li>
								<li>
									<input type="radio" id="disagree4" name="discussion" value="Disagree" checked={feedbackData.discussion === 'Disagree'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="disagree4">Disagree</label>
								</li>
								<li>
									<input type="radio" id="stronglyDisagree4" name="discussion" value="Strongly Disagree" checked={feedbackData.discussion === 'Strongly Disagree'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="stronglyDisagree4">Strongly Disagree</label>
								</li>
							</ul>
						</div>
						<div className="feedBackEmotions">
							<p>Overall Rating</p>
							<ul>
								<li>
									<input type="radio" id="rate1" name="rate" value="1" checked={feedbackData.rate === '1'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="rate1">1</label>
								</li>
								<li>
									<input type="radio" id="rate2" name="rate" value="2" checked={feedbackData.rate === '2'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="rate2">2</label>
								</li>
								<li>
									<input type="radio" id="rate3" name="rate" value="3" checked={feedbackData.rate === '3'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="rate3">3</label>
								</li>
								<li>
									<input type="radio" id="rate4" name="rate" value="4" checked={feedbackData.rate === '4'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="rate4">4</label>
								</li>
								<li>
									<input type="radio" id="rate5" name="rate" value="5" checked={feedbackData.rate === '5'}
                                    onChange={handleFeedbackChange}/>
									<label htmlFor="rate5">5</label>
								</li>
							</ul>
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<div className="d-flex">
						<button type="button" className="btn btn-secondary" onClick={handleFeedbackSubmit}>
							Submit
						</button>
						<button type="button" className="btn btn-secondary mx-3" onClick={handleFeedBackModalClose}>
							Close
						</button>
					</div>
				</Modal.Footer>
			</Modal>

			<Modal show={angryFeedBackModal} onHide={handleAngryFeedBackModalClose}>
				<Modal.Header closeButton>
					<Modal.Title>Feedback</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form className="feedBackEmotionForm">
						<div className="form-group">
							<label htmlFor="sessionLocation">Session Location</label>
							<select required
								className="form-control"
								id="sessionLocation"
								name="sessionLocation"
								value={angryFeedbackData.sessionLocation}
                				onChange={handleInputChange}
							>
								<option selected disabled value="">Select Location</option>
								<option value="Park Royal">Park Royal</option>
								<option value="Barking">Barking</option>
								<option value="Croydon">Croydon</option>
								<option value="Birmingham">Birmingham</option>
								<option value="Manchester">Manchester</option>
								{/*<option value="Online Group 1">Online Group 1</option>
								<option value="Online Group 2">Online Group 2</option>
								<option value="Online BP G1">Online Bookkeeping & Payroll G1</option>
								<option value="Online BP G2">Online Bookkeeping & Payroll G2</option>
								<option value="Online AA G1">Online Accounts Assistant G1</option>
								<option value="Online AA G2">Online Accounts Assistant G2</option>*/}
								<option value="Online BP">Online Bookkeeping & Payroll</option>
								<option value="Online AA">Online Accounts Assistant</option>
								<option value="FA Practice">KBM_FA_Practice</option>
							</select>
						</div>
						<div className="form-group">
							<label htmlFor="course">Course</label>
							<select required
								className="form-control"
								id="course"
								name="course"
								value={angryFeedbackData.course}
								onChange={handleCourseChange}
								// value={angryFeedbackData.course}
                				// onChange={handleInputChange}
							>
								<option selected disabled value="">Select Course</option>
								{Array.isArray(courses) && courses.map((course, index) => (
									<option key={index} value={course.courseName}>
										{course.courseName}
									</option>
								))}
							</select>
						</div>
						<div className="form-group">
							<label htmlFor="client">Client</label>
							<select required
								className="form-control"
								id="client"
								name="client"
								value={angryFeedbackData.client}
                				onChange={handleInputChange}
							>
								<option disabled value="">Select Client</option>
								{clients.map((client, index) => (
									<option key={index} value={client}>
										{client}
									</option>
								))}
							</select>
						</div>
						<div className="form-group">
							<label htmlFor="topic">Topic</label>
							<select required
								className="form-control"
								id="topic"
								name="topic"
								value={angryFeedbackData.topic}
                				onChange={handleInputChange}
							>
								<option disabled value="">Select Topic</option>
								<option value="Sale Ledger">Sale Ledger</option>
								<option value="Purchase Ledger">Purchase Ledger</option>
								<option value="Stock">Stock</option>
								<option value="Patty Cash">Patty Cash</option>
								<option value="VAT Rate">VAT Rate</option>
								<option value="Corporation tax Rate">Corporation tax Rate</option>
								<option value="Accrual">Accrual</option>
								<option value="Prepayment">Prepayment</option>
								<option value="Discount">Discount</option>
								<option value="Non-Current Asst">Non-Current Asst</option>
								<option value="Reverse Charge">Reverse Charge</option>
								<option value="Bank Ledger">Bank Ledger</option>
								<option value="Nominal Codes">Nominal Codes</option>
								<option value="Bank Ledger">Bank Ledger</option>
								<option value="General Query">General Query</option>
							</select>
						</div>
						<div className="form-group">
							<label htmlFor="software">Software</label>
							<select required
								className="form-control"
								id="software"
								name="software"
								value={angryFeedbackData.software}
                				onChange={handleInputChange}
							>
								<option disabled value="">Select Software</option>
								<option value="Sage 50 Accounts">Sage 50 Accounts</option>
								<option value="Sage Payroll">Sage 50 Payroll</option>
								<option value="Excel">Excel</option>
								<option value="Quickbooks">Quickbooks</option>
								<option value="Xero">Xero</option>
							</select>
						</div>
						<div className="form-group">
							<label htmlFor="description">Description</label>
							<textarea type="text" name="description" value={angryFeedbackData.description}
                			onChange={handleInputChange}></textarea>
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<div className="d-flex">
						<button type="button" className="btn btn-secondary" onClick={handleAngryFeedbackSubmit}>
							Submit
						</button>
						<button type="button" className="btn btn-secondary mx-3" onClick={handleAngryFeedBackModalClose}>
							Close
						</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Notifications